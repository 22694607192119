$border-dashed: 1px dashed $gray-200;

.accordion {
  &--bubble {
    //TODO: These sizes could be shared from _icon.scss
    min-width: 1.5rem;
    &__large {
      min-width: 2.5rem;
      @media #{$md} {
        min-width: 3.75rem;
      }
    }
    .bubble--background {
      stroke: $white;
      transition: background-color 0.2s ease-in;
      will-change: background-color;
    }
  }
  &--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    background: none;
    width: 100%;
    min-height: 4rem;
    &-content {
      padding: 0 0.5rem;
      flex-wrap: wrap;
      @media #{$sm} {
        padding: 0 1.25rem;
        flex-wrap: nowrap;
      }
    }
    &__flush {
      padding-left: 0;
    }

    &--heading {
      text-align: left;
      flex-basis: 100%;
      @media #{$sm} {
        flex-basis: auto;
      }
    }

    &--subheading {
      white-space: nowrap;
      @media #{$sm} {
        padding-left: 0.75rem;
      }
    }
  }
  &--panel {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-bottom: $border-dashed;

    &__dash-top {
      border-top: $border-dashed;
      border-bottom: none;
    }

    &-content {
      padding: 1rem 0;

      &__full {
        padding: 0;
      }
    }

    &__bubble-bump {
      .accordion--panel-content > * {
        @media #{$sm} {
          // sets the padding on the left of whatever the child is to be flush with header when there is bubble
          // this is definitely weird but we have to do it so background can be full width
          padding-right: 1.5rem;
          padding-left: 5.5rem;
        }
      }
    }
  }

  .input--constrained {
    margin: unset;
  }
}

kin-review-accordion + kin-review-accordion {
  margin-top: 2rem;
}
