@use 'theme/colors';
@use 'theme/variables';

@mixin input-textbox($height: 3rem) {
  display: block;
  height: $height;
  width: auto;
  border: 0;
  border-radius: 0;
  padding: 0.5rem 0 2px 0;
  margin: 0;
  border-bottom: 1px solid colors.$secondary;
  color: colors.$warm-black;
  font-family: variables.$font__sans;
  font-size: #{variables.$base-input-font-size}px;
  font-weight: inherit;
  line-height: $height;
  background-color: transparent;
  text-overflow: ellipsis;
  overflow: hidden;

  &:focus {
    outline: 0;
    border-bottom-width: 3px;
    transition: border .0625s ease-in-out, padding-bottom .0625s ease-in-out;
    padding-bottom: 0;
  }

  &[disabled] {
    cursor: not-allowed;
    border-bottom-color: colors.$dark-gray;
    opacity: 0.4;
  }

  &::placeholder {
    color: transparent;
    visibility: hidden;
  }

  &:focus::placeholder {
    color: colors.$brand__gray;
    visibility: visible;
  }
  &__always-activate-label::placeholder {
    color: colors.$brand__gray;
    visibility: visible;
  }
}

@mixin label-as-placeholder-text {
  bottom: .75rem;
  color: rgba(colors.$brand__gray, .7);
  font-size: #{variables.$base-input-font-size}px;
}

@mixin disabled-input-label {
  color: colors.$brand__gray;
  opacity: 0.4;
}

@mixin border-radius($radius) {
  border-radius: $radius;

  background-clip: padding-box;
  -webkit-border-radius: $radius;
}

@mixin header-button {
  min-width: 44px;
  min-height: 44px;
  display: block;
  position: relative;
  background: transparent;
  cursor: pointer;
  border: 1px solid transparent;
  appearance: none;
}

@mixin sr-only {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

@mixin vertically-center {
  top: 50%;
  transform: translateY(-50%);
}

@mixin alternate-background {
  &:nth-of-type(odd) {
    background-color: colors.$gray__lighter;
  }
}

@mixin autocomplete-item {
  padding: 10px 18px;
  &:nth-child(even) {
    background-color: colors.$primary__light;
  }
  &:hover,
  &:hover:nth-child(even),
  &--selected,
  &--selected:nth-child(even),
  &-selected,
  &-selected:nth-child(even) {
    background-color: colors.$primary__tone;
  }
  &, &-query {
    color: colors.$warm-black;
    font-family: variables.$font__sans;
    font-size: 16px;
    line-height: 26px;
  }
}

@mixin autocomplete-list {
  background-color: colors.$white;
  border-radius: 8px;
  border: 1px solid colors.$gray-200;
}

@mixin max-width-height($value_in_px) {
  max-width: px-to-rem($value_in_px);
  max-height: px-to-rem($value_in_px);
}

@mixin card {
  position: relative;
  background-color: colors.$white;
  border: 1px solid colors.$gray__light;
  border-radius: variables.$radio-card-border-radius;
  flex: 1 0;
  margin: 0;
  color: colors.$black;
  transition: transform 0.2s ease-in-out, border-color 0.3s ease-in-out;
  will-change: filter;
  filter: drop-shadow(0px 2px 3px rgba(33, 33, 33, 0.12));
}

@mixin visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@mixin fixed-aspect-ratio($ratio: 1) {
  display: flex;
  flex-wrap: wrap;

  &__background {
    position: relative;
    flex-basis: 100%;
    &::before {
      content: '';
      display: block;
      padding-top: $ratio * 100%;
    }
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

@mixin absolute-center {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
