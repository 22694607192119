// Tertiary
$tertiary: #8e5cd1;
$tertiary-100: #f6f1fd;
$tertiary-200: #c9badc;
$tertiary-600: #64468b;

// Blue - used for flood / and the pricing boxes in KSO
$blue: #2962ff;
$blue-100: #dee7ff;

// Utility
$warning: #cb0019;
$warning-100: #fae6e8;
$warning-200: #f4cace;
$warning-600: #68000d;

$success: #00cd1a;
$success-100: #e6fae8;
$success-600: #00600c;

// Grays
$gray: #bdbdbd;
$gray-lanes: #f2f2f2;
$gray-050: #fafafa; // Consider changing back to $background;
$gray-100: #f5f5f5;
$gray-200: #e0e0e0;
$gray-600: #757575;
$gray-700: #737373;
$gray-900: #616161;

//
// New Brand Colors
//

// Primary
$primary: #ffc857; //golden tainoi
$primary__tone: #fae7b8; //astra
$primary__light: #fbf8f3; //fantasy
$primary__bold: #fbc02d;

// Supporting Colors
$secondary: #167c80; //cyan
$secondary__shade: #126568; //dark cyan
$secondary__tone: #3193ad; //deep spring blue
$secondary-100: #E8F2F3; //cyan 10%
$secondary__shade-200: #D0E0E1; //dark cyan 20%

$tertiary__tone: #007055; //tropical rain forest
$tertiary__shade: #004a38; //aqua deep
$brand__gray: #545c52; //ebony
$astra: #FAE7B8; //astra

// state
$brand__red: #b11030; //bright red
$brand__red__tone: #dd563e; //pea flame

// B&W
$white: #fff;
$dark-gray: #757575;
$warm-black: #242221;
$black: #212121;

// Grays
$gray__background: #f9f9f9;
$gray__lighter: #f7f7f7;
$gray__tone: #eeeeee;
$gray: #bdbdbd;
$gray__shade: #e0e0e0;
$gray__light: #dbdddb;
