@import '@kin/css/css/tokens.css';
@import './basics/reset';
@import './theme/fonts';
@import './theme/variables';
@import './basics/functions';
@import './theme/colors';
@import './mixins/mixins';
@import 'basics/elements';
@import './basics/funnel-elements';
@import './print-styles';

@import './components/accordion';
@import './components/alert';
@import './components/alert-bar';
@import './components/autocomplete';
@import './components/badge';
@import './components/bubble';
@import './components/buttons';
@import './components/card';
@import './components/datepicker';
@import './components/heading';
@import './components/icon';
@import './components/loader';
@import './components/logo';
@import './components/menu';
@import './components/modal';
@import './components/progress-bar';
@import './components/review';
@import './components/tooltip';
@import './components/hint';
@import './components/testimonial';
@import './components/toggle';
@import './components/trustpilot';
@import './components/quote-card';
@import './components/header-circle';
@import './components/credit-card';
@import './components/table';
@import './components/message-with-underline';
@import './components/external-link';
@import './components/address-bar';

@import './external/address-autocomplete';

@import './forms/radio-card';
@import './forms/textbox';
@import './forms/checkbox';

@import './layout/wrapper';
@import './layout/header';
@import './layout/footer';
@import './layout/grid';
@import './layout/v-list';
@import './layout/split';
@import './layout/h-list';
@import './layout/h-grid';
@import './layout/v-to-h-list';
@import './layout/vr';

@import './modules/page-builder';

@import './utilities/utilities';

@import './typography/line-height';
@import './typography/text';
@import './typography/heading-type';

@media (prefers-reduced-motion: reduce) {
  * {
    animation: none;
    transition: none;
  }
}
