.highlight-text {
  position: relative;
  z-index: 1;

  &::after {
    background: $primary;
    bottom: 10%;
    content: "";
    height: 0.7rem;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: -1;
  }
}
