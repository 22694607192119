.logo {
  display: inline-block;
  height: 1.5rem;
  margin: 1rem;

  svg {
    height: inherit;
  }
  &.logo__xsmall {
    height: 0.825rem;
  }
  &.logo__small {
    height: 1rem;
  }
  &.logo__medium {
    height: 1.5rem;
  }
  &.logo__large {
    height: 2rem;
  }
  &.logo__xlarge {
    height: 3rem;
  }
  &.logo__xxlarge {
    height: 4.5rem;
  }
}
