@use 'theme/colors';
/* /////////////////////// Bubble Circle

decorative circle

  functional css pattern example:
  <div class="bubble">
    <div class="bubble--background">
      <div class="bubble--content">
      </div>
    </div>
  </div>

*/ ///////////////////////
.bubble {
  display: flex;
  flex-wrap: wrap;
  &--background {
    position: relative;
    border-radius: 50%;
    background-color: colors.$secondary;
    flex-basis: 100%;
    &__square {
      border-radius: 4px;
    }
    &__alt {
      background-color: rgba(colors.$secondary, 0.1)
    }
    &__transparent {
      background-color: transparent;
    }
    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }
  }
  &--content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 1.25rem;
  }
}
