@use 'theme/colors';

@mixin primary-focus {
  outline: none;
  box-shadow: 0 0 0 6px colors.$primary__tone;
}

@mixin secondary {
  background-color: colors.$white;
  color: colors.$secondary;
  border: 1px solid colors.$secondary;
}

@mixin secondary-active {
  color: colors.$secondary;
  background-color: colors.$secondary-100;
}

@mixin secondary-hover {
  color: colors.$secondary__shade;
  background-color: colors.$secondary__shade-200;
}
