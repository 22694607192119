.v-to-h-list {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  list-style: none;
  margin-left: 0;
  padding-left: 0;

  &:not(.v-to-h-list--bare) > * {
    margin-bottom: 1rem;
  }
  
  &:not(.v-to-h-list--bare) > :last-child {
    margin-bottom: 0;
  }

  &--right {
    justify-content: flex-end;
  }
}

// Make this into a mixin :)

@media #{$md} {
  .v-to-h-list {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
    flex-wrap: wrap;
    list-style: none;

    &:not(.v-to-h-list--bare) > * {
      margin-right: 1rem;
    }
    
    &:not(.v-to-h-list--bare) > :last-child {
      margin-right: 0;
    }

    &--right {
      justify-content: flex-end;
    }
  }
}
