.testimonial {
  align-items: center;
  background-color: $primary__light;
  border-radius: px-to-rem(8px);
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: px-to-rem(50px) px-to-rem(22px) px-to-rem(20px) px-to-rem(45px);
  width: 100%;

  @media #{$md} {
    padding: px-to-rem(55px) px-to-rem(22px) px-to-rem(22px) px-to-rem(52px);
  }

  &__quote {
    color: $warm-black;
    font-size: px-to-rem(16px);
    line-height: unitless-line-height(26px, 16px);
    font-weight: 400;
    padding-bottom: px-to-rem(18px);
    position: relative;
    z-index: 1;

    &::before {
      background: url('/assets/icons/quote-primary.svg');
      content: '';
      display: block;
      height: px-to-rem(41px);
      left: px-to-rem(-22px);
      position: absolute;
      top: px-to-rem(-20px);
      width: px-to-rem(47px);
      z-index: -1;
    }
  }

  &__author {
    align-self: flex-end;
    color: $secondary;
    font-size: px-to-rem(16px);
    margin-top: 0.5rem;
    position: relative;
    text-decoration: none;

    &::before {
      content: '\2014'; // \2014 is "—"
      left: px-to-rem(-20px);
      position: absolute;
      top: -1px;
    }

    &--link {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
