.review {
  form {
    width: 100%;
  }

  h1 {
    // TODO review this with Design to see if we want it sitewide
    margin-bottom: 0.75rem;
    align-self: flex-start;
  }

  h2 {
    margin-bottom: 1.5rem;
    align-self: flex-start;
  }

  hr {
    background-color: $gray__shade;
  }

  &__metadata {
    align-self: flex-start;
    width: 100%;
  }

  &__customize {
    @media #{$sm} {
      padding-bottom: 2rem;
    }

    .review__add-on {
      @include alternate-background;
      padding: 1rem;

      @media #{$md} {
        padding-left: 5rem;
        padding-right: 2.75rem;
      }
    }
    .accordion--header-content {
      > * {
        @media #{$sm} {
          text-align: left;
          flex-basis: 20%;

          &:first-child {
            flex-basis: 60%;
          }

          &:last-child:not(:only-child) {
            text-align: right;
          }
        }
      }
    }
  }

  &__summary {
    position: relative;
    &--mobile {
      background-color: $white;
      bottom: 0;
      box-shadow: 0px 1px 8px rgba(33, 33, 33, 0.12);
      clip-path: inset(-10px 0px 0px 0px);
      padding: 1rem;
      position: fixed;
      position: sticky; // still not supported on IE but this is only mobile
      height: 8rem;
      width: 100%;
      @media #{$md} {
        display: none;
      }
      &--print {
        width: 100%;
        height: 100%;
        box-shadow: none;
        border-top: 1px solid $gray__shade;
        border-bottom: 1px solid $gray__shade;
        padding-top: 30px;
        padding-bottom: 20px;
        margin-bottom: 10px;
      }
    }
  }
}
