@mixin attached-border {
  border-radius: 0;
  &:first-child {
    border-radius: $radio-card-border-radius 0 0 $radio-card-border-radius;
  }
  &:last-child {
    border-radius: 0 $radio-card-border-radius $radio-card-border-radius 0;
  }
}

.radio-card {
  @include card();
  display: flex;
  flex-direction: column;
  height: 150px;
  align-items: center;
  padding: 1rem;

  &__narrow {
    min-width: 0;
    padding: 1rem 2rem;
  }

  &__attached {
    @include attached-border();
    filter: unset;
    flex: 0 1 110px;
    flex-wrap: nowrap;
    min-width: unset;
    height: auto;
    padding: 0.5rem;
    white-space: nowrap;

    &:not(:first-child) {
      border-left-width: 0;
    }
    .radio-card--inner,
    .radio-card--inner--background {
      border-radius: inherit;
    }
  }

  &__no-image {
    height: auto;
    .radio-card--inner--text {
      color: $secondary;
    }
  }

  &--inner {
    appearance: none;
    margin: 0;
    padding: 0;
    border: 0;
    background: none;
    display: flex;
    flex-direction: column;
    font-size: 0.875rem; // 14 px when root of 16px
    align-items: center;
    z-index: 0;
    height: 100%;
    width: 100%;

    &--image {
      width: 4rem;
      height: 4rem;
      position: relative;

      svg {
        position: absolute;
        left: 0;
        max-height: 4rem;
        max-width: 4rem;
      }

      &__full-width {
        width: 100%;
        height: 94px;
        overflow: hidden;

        img {
          border-radius: $radio-card-border-radius $radio-card-border-radius 0 0;

          width: 100%;
        }
      }
    }

    &--spacer {
      max-height: 20px;
      flex: 1 1 auto;
    }

    &--background {
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: $radio-card-border-radius;
      filter: drop-shadow(0px 4px 12px rgba(33, 33, 33, 0.12));
    }

    input[type="radio"] {
      opacity: 0;
      position: absolute;
      width: 0px;
      height: 0px;
      z-index: -1;

      &:focus ~ .radio-card--inner--background {
        border: 2px solid $secondary;
      }

      &:checked ~ .radio-card--inner--background {
        border: 2px solid $secondary;
      }

      .radio-card__attached &:checked {
        ~ .radio-card--inner--background {
          background-color: $secondary;
        }
        ~ .radio-card--inner--text {
          color: $white;
        }
      }
    }

    &--text {
      color: $brand__gray;
      text-align: center;
      flex-grow: 1;
      align-items: center;
      display: flex;

      &__has-tooltip {
        padding: 0 1rem; // padding is tooltip width plus gutters
      }
      .radio-card--inner--image ~ & {
        margin-top: 1rem;
      }
    }

    &--description {
      color: $black;
      font-size: 14px;
    }
  }

  &__full-width {
    &.radio-card {
      // TODO do we want these to be default min dimensions? If so, add to mixin
      min-height: 150px;
      width: 180px;
      padding: 0;
    }

    .radio-card--inner--text {
      height: 56px;
      align-items: start;
      margin-top: 0;
      padding: 0.25rem 0 0;
    }
  }

  &:hover {
    transform: translateY(-2%);
    border-color: $secondary;
    button {
      cursor: pointer;
    }
  }

  &:active {
    filter: drop-shadow(0px 2px 6px rgba(33, 33, 33, 0.12));
  }

  &:not(&__attached){
    width: 100%;
  }
}
