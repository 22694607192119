@use 'theme/variables';

.k-modal {
  display: none;
}

.k-modal.is-active {
  display: block;
}

.k-modal__overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000000;
}

.k-modal__content {
  position: fixed;
  top: calc(var(--header-group-height) + 1rem);
  left: 1rem;
  right: 1rem;
  margin: 0 auto;
  max-height: calc(100vh - var(--header-group-height) + 2rem);
  z-index: 1000013;
  background-color: #fff;
  max-width: 40rem;
  box-shadow: 0 4px 12px rgba(33, 33, 33, 0.56);
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
}

@media #{$sm} {
  .k-modal__content {
    top: max(20vh, calc(var(--header-group-height) + 1rem));
    max-height: calc(80vh - var(--header-group-height));
  }
}

.k-modal__content__image {
  padding: 0 0 1.5rem;
}

.k-modal__content__header__close {
  position: absolute;
  top: 4px;
  right: 4px;
}

.k-modal__content__header__close button {
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  background: transparent;
  width: 44px;
  height: 44px;
  &:focus {
    outline: 0.125rem solid rgba(0, 0, 0, 0.65);
    outline-offset: -0.25rem;
  }
}

.k-modal__content__header__close .n-icon {
  width: 16px;
  height: 16px;
}

.k-modal__content__body {
  flex: 0 1 auto;
  max-height: calc(100% - 72px);
  height: 100%;
  overflow: auto;
}

.k-modal__content__body--allow-overflow {
  overflow: visible;
}

.k-modal__content__header,
.k-modal__content__body,
.k-modal__content__footer {
  padding: 1rem 2rem;
}

.k-modal__content__footer--empty,
.k-modal__content__footer:empty {
  padding: 0;
}

.k-modal__content__body__footer {
  margin-top: 2em;
}

.k-modal__content__header__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -5rem;
  margin-bottom: -1rem;
}

.k-modal__content__header__icon__image {
  width: 10rem;
  height: 10rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  box-shadow: 1px 1px 5px rgba(33, 33, 33, 0.56);
  border: 0.25rem solid $white;
  border-radius: 50%;
}

.k-modal__content__header__icon__image > * {
  width: auto;
  max-width: none;
  height: 100%;
  color: $secondary;
}

.k-modal__content__header__icon__image .icon__modal-header,
.k-modal__content__header__icon__image svg {
  height: 60%;
  stroke: $secondary;
  fill: $secondary__tone;
  stroke-width: 2px;
}

.sr-only {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap; /* added line */
}

//modal
.has-open-modal {
  max-height: 100vh;
  height: 100%;
  overflow: hidden;
}

.has-open-modal .pac-container {
  z-index: 1000050;
}
