.vr {
  margin-bottom: #{$base-unit}px;
}

@mixin vr($multiplier, $name) {
  @if $name == "" {
    .vr__x#{$multiplier} {
      margin-bottom: #{$base-unit * $multiplier}px;
    }
  } @else {
    .vr__x#{$multiplier}\@#{$name} {
      margin-bottom: #{$base-unit * $multiplier}px;
    }
  }
}

@each $name, $breakpoint in $breakpoints {
  @if $breakpoint != "none" {
    .vr\@#{$name} {
      margin-bottom: #{$base-unit}px;
    }
  }

  @for $multiplier from 0 through 26 {
    @if $breakpoint == "none" {
      @include vr($multiplier, "")
    } @else {
      @media only screen and (min-width: $breakpoint) {
        @include vr($multiplier, $name)
      }
    }
  }
}
