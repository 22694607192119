kin-page-builder {
  .grid {
    display: flex;
    flex-direction: column;

    .grid__submit {
      margin: 2rem auto 0;
      width: 100%;
    }
  }
}

@media #{$md} {
  kin-page-builder {
    .grid {
      display: grid;
      column-gap: 2rem;
      row-gap: 0.5rem;

      .grid__submit {
        margin: 1.5rem auto 0;
        width: 50%;
      }
    }

    .grid--columns {
      &--2 {
        grid-template-columns: repeat(2, 1fr);

        .grid__submit {
          grid-column: span 2;
        }
      }
    }
  }
}
