@use 'theme/colors';
@use 'theme/fonts';
@use 'theme/variables';
@use 'mixins/mixins';

.text {
  font-family: variables.$font__sans;
  line-height: 1.4;
  &__center {
    text-align: center;
  }
  &__left {
    text-align: left;
  }
  &__right {
    text-align: right;
  }
  &__bold {
    font-weight: 500;
  }
  &__bolder {
    font-weight: 700;
  }
  &__normal {
    font-weight: normal;
  }
  &__italic {
    font-style: italic;
  }
  &__x-sm {
    font-size: 0.625rem;
  }
  &__sm {
    font-size: 0.75rem;
  }
  &__md {
    font-size: 0.875rem;
  }
  &__lg {
    font-size: 1.125rem;
  }
  &__xl {
    font-size: 1.3125rem;
  }
  &__link {
    text-decoration: underline;
    color: colors.$secondary;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }

    &--menu {
      color: colors.$dark-gray;
      position: relative;
      text-decoration: none;
      &:hover {
        color: colors.$secondary;
      }
      &:hover:before {
        content: '';
        width: 8px;
        height: 8px;
        bottom: 8px;
        right: -1.5rem;
        position: absolute;
        border-left: 2px solid colors.$secondary;
        border-top: 2px solid colors.$secondary;
        transform: rotate(135deg);
      }
    }

    &--button {
      padding: 0.5rem;
      border: 1px solid colors.$gray-900;
      border-radius: 8px;
    }

    &--plain {
      text-decoration: none;
    }

    &--underline {
      text-underline-offset: 5px;
    }

    &--underline-mobile {
      text-underline-offset: 5px;
      @media #{$sm} {
        text-decoration: none;
      }
    }
  }
  &__link--borderless {
    text-decoration: underline;
    background: transparent;
    border: 0;

    &:hover {
      text-decoration: none;
    }
  }

  &__small-caps {
    font-variant: small-caps;
  }

  &__secondary {
    color: colors.$secondary;
  }
  &__warning {
    color: colors.$brand__red;
  }
  &__success {
    color: colors.$success;
  }
  &__success-600 {
    color: colors.$success-600;
  }
  &__blue {
    color: colors.$blue;
  }
  &__uppercase {
    text-transform: uppercase;
  }
  &__wide {
    letter-spacing: 0.05em;
  }
  &__tertiary {
    color: colors.$tertiary-600;
  }
  &__brand-gray {
    color: colors.$brand__gray;
  }
  &__dark-gray {
    color: colors.$dark-gray;
  }
  &__gray {
    color: colors.$gray;
  }
  &__white {
    color: colors.$white;
  }
  &__warm-black {
    color: colors.$warm-black;
  }

  &__secondary-hover {
    &:hover,
    &:focus {
      color: colors.$secondary;
    }
  }
  &__h-space {
    margin: 0 0.25rem;
  }
  &__hint {
    font-size: 0.875rem;
    color: colors.$gray-900;
  }
  &__inline-block {
    display: inline-block;
  }
  &__block {
    display: block;
  }
  &__overflow-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &__break-word {
    overflow-wrap: break-word;
    hyphens: auto;
  }
  &__capitalize::first-letter {
    text-transform: uppercase;
  }

  &__margin-auto {
    margin: auto;
  }
}

@media #{variables.$sm} {
  .text {
    &__center\@small {
      text-align: center;
    }
  }
}

@media #{variables.$md} {
  .text {
    &__left\@medium {
      text-align: left;
    }
    &__right\@medium {
      text-align: right;
    }
    &__x-sm\@medium {
      font-size: 0.625rem;
    }
    &__sm\@medium {
      font-size: 0.75rem;
    }
    &__md\@medium {
      font-size: 0.875rem;
    }
    &__lg\@medium {
      font-size: 1.125rem;
    }
    &__xl\@medium {
      font-size: 1.3125rem;
    }
  }
}

@mixin text($size, $name) {
  @if $name == '' {
    .text__size--#{$size} {
      font-size: #{$size}px; // convert to rem;
    }
  } @else {
    .text__size--#{$size}\@#{$name} {
      font-size: #{$size}px; // convert to rem;
    }
  }
}

$sizes: [9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 32, 36, 40, 44, 60, 72];

// Expand this to work across breakpoints, similar to grid.
@each $name, $breakpoint in variables.$breakpoints {
  @each $size in $sizes {
    @if $breakpoint == 'none' {
      @include text($size, '');
    } @else {
      @media only screen and (min-width: $breakpoint) {
        @include text($size, $name);
      }
    }
  }
}

.sr-only {
  @include mixins.sr-only();
}
