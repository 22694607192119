@use 'theme/variables';
@use 'basics/functions';

.h-grid {
  display: grid;
  grid-gap: functions.px-to-rem(20px);
  list-style: none;
  padding: 0;
}

.h-grid--lg-gap {
  grid-gap: functions.px-to-rem(24px);
}

.h-grid--auto {
  grid-auto-columns: auto;
  grid-auto-flow: column;
}

// Generalize this later.
@media #{variables.$sm} {
  .h-grid--max-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .h-grid--max-2,
  .h-grid--max-3,
  .h-grid--max-4 {
    // TODO add more classes or handle programmatically if we want to support more max columns
    // on tablet anything with more than one column should max to two columns
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media #{variables.$mg} {
  // TODO grid doesn't kick in until large screens; not sure this is the best approach and maybe we want to use flex
  $grid-sizes: (
    1: 1,
    2: 2,
    3: 3,
    4: 4,
  );

  @each $size, $grid-size in $grid-sizes {
    .h-grid--max-#{$size} {
      grid-template-columns: repeat($grid-size, minmax(0, 1fr));
    }
  }
}
