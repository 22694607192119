$coverages-gutter: 4px;
$quote-card-width: 385px;

.quote-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  flex: 1 0;
  margin: 0;
  color: #545c52;
  padding: 2rem;
  will-change: transform;
  transition: transform .2s ease-in-out;
  overflow: hidden; // Safari doesn't support clip
  overflow: clip;
  max-width: $quote-card-width;
  filter: drop-shadow(0px 4px 12px rgba(33, 33, 33, 0.12));

  .quote-card--inner__invertible {
    will-change: auto;
    transition: color .2s ease-in-out;
  }

  &__tertiary {
    color: $white;

    .quote-card--inner__invertible {
      color: $black;
    }

    .quote-card--background,
    .btn {
      background-color: $tertiary__tone;
    }

    .btn:focus {
      box-shadow: 0 0 0 6px $astra;
    }
  }

  &__primary {
    color: $black;

    .quote-card--background,
    .btn {
      background-color: $primary;
      color: $black;
    }

    .btn:focus {
      box-shadow: 0 0 0 6px $secondary__tone;
    }
  }

  &__primary--light {
    color: $black;

    .quote-card--background {
      background-color: $primary__light;
    }
  }

  &__black {
    color: $white;

    .quote-card--inner__invertible {
      color: $black;
    }

    .quote-card--background,
    .btn {
      background-color: $black;
    }

    .btn:focus {
      box-shadow: 0 0 0 6px $astra;
    }
  }

  &--background {
    position: absolute;
    top: -2rem;
    width: 100%;
    height: 100%;
    z-index: -1;
    clip-path: ellipse(78.46% 30.57% at 50.26% 25.36%);
    will-change: auto;
    transition: width .2s ease-in-out, height .2s ease-in-out, clip-path .2s ease-in-out, transform 0.2s ease-in-out;

    // these next two rules are scoped to the content height of specific scenarios and should only be used on those pages
    &__compare,
    &__summary {
      height: 36rem;
    }

    ;

    // @todo - Split Cleanup ar-quoting-single_quote_identifier
    &__summaryAB {
      height: 28rem;
    }
  }

  .quote-card--inner {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &--details {
      width: 100%;

      @media (min-width: $small) {
        max-height: 50vh;
        overflow-y: auto;
      }
    }

    &--header,
    &--price {
      display: flex;
      flex-direction: column;
      align-items: center;

      .quote-card--inner--price__base-price {
        // transition: font-size 0.2s ease-out;
        // will-change: font-size;
      }

      .quote-card--inner--price__undiscounted-price {
        // transition: opacity 0.2s ease-in, font-size 0.2s ease-out, height 0.2s ease-out;
        // will-change: opacity, font-size, height;
        opacity: 0;
        height: 0;
        text-decoration: line-through;
      }
    }

    &--price.wind-mitigation {
      .quote-card--inner--price__base-price {
        // transition: font-size 0.2s ease-out;
        // will-change: font-size;
      }

      .quote-card--inner--price__undiscounted-price {
        // transition: opacity 0.2s ease-in, font-size 0.2s ease-out, height 0.2s ease-out;
        // will-change: opacity, font-size, height;
        opacity: 1;
        height: auto;
      }
    }

    &__left {
      align-self: start;
    }
  }

  .quote-card--coverages {
    display: flex;
    flex-wrap: wrap;

    >* {
      border-bottom: 1px solid $gray;
      flex-basis: calc(50% - 2 * #{$coverages-gutter});
      margin: 0 $coverages-gutter;
      padding: 20px 0;
    }

    .quote-card--coverages--coverage__no-underline {
      border-bottom: 0;
    }

    .quote-card--coverages--coverage__full-width {
      flex-basis: 100%;
    }
  }

}

.quote-card--summary {
  width: 100%;
  display: none; // TODO once we have coverages in /polcies endpoint show this again

  @media #{$sm} {
    width: $quote-card-width;
  }

  @media #{$md} {
    display: flex; // TODO can remove with above display:none
  }

  .v-list {
    width: 100%
  }

  .btn {
    width: 90%;
    max-width: 327px; // width from buttons.scss
  }

  & .quote-card--inner {
    // this keeps the card tall enough in the rare case we don't have a tagline or title to display
    // @todo - Split Cleanup ar-quoting-single_quote_identifier
    // min-height: 17rem; //Original
    min-height: 9rem;
  }

  &-items {
    // TODO confirm this is still ok when uncommening accordions
    display: flex;
    flex-direction: column;

    >* {
      border-bottom: 1px dashed $gray;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;

      &:last-of-type {
        border-bottom: 0;
      }
    }
  }
}

.quote-card__no-animation {

  // this block is for better scrolling under the ellipse
  .quote-card--inner--header,
  .quote-card--inner--price {
    z-index: 1;
  }

  .quote-card--background {
    z-index: 0;
  }
}

@media (min-width: 48em) {
  .quote-card:not(.quote-card--summary) {
    min-width: $quote-card-width;
    filter: unset;

    &:hover,
    &:focus-within {
      // outline: 1px solid $tertiary__tone; // pretty sure we don't want this but leaving commented in case I'm wrong
      filter: drop-shadow(0px 4px 12px rgba(33, 33, 33, 0.12));
      z-index: 3;
    }
  }
}

@media (prefers-reduced-motion: no-preference) {
  .quote-card {

    &:hover:not(.quote-card__no-animation),
    &:focus-within:not(.quote-card__no-animation) {
      outline: 0;
      transform: scale(1.1);
      z-index: 3;

      .btn {
        background-color: $white;
        color: $black;
      }

      &:not(.quote-card__no-animation) .quote-card--background {
        clip-path: ellipse(65.41% 98.11% at 50.26% 25.36%);
        width: calc(100% + 4rem);
        height: calc(100% + 4rem);
      }
    }

    &__black,
    &__tertiary {

      &:hover,
      &:focus-within {

        .quote-card--inner__invertible,
        .text__brand-gray {
          color: $white;
        }
      }
    }

    &__primary,
    &__primary--light {

      &:hover,
      &:focus-within {
        .quote-card--coverages>* {
          border-color: $black;
        }
      }
    }

    &__tertiary {

      &:hover,
      &:focus-within {
        .quote-card--coverages>* {
          border-color: $white;
        }
      }
    }
  }

  .quote__body--cards:hover kin-quote-card:nth-child(2) {
    transition: transform .2s ease-in-out;
    z-index: 1;
  }

  .quote__body--cards:not(:hover) kin-quote-card:nth-child(2) {
    transform: scale(1.1);
    z-index: 2;
    filter: drop-shadow(0px 4px 12px rgba(33, 33, 33, 0.12));
    transition: transform .2s ease-in-out;
  }
}