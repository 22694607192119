/* /////////////////////// Heading & Subheading Styles

Heading styles: html heading elements should go in page order h1-> h6 for semantic & a11y purposes. heading styles should match html element frequently used patterns can be broken out into a css component otherwise use a functional css pattern

  functional css pattern example:
  <h4 class="hdg hdg__4 hdg__secondary">Policy Documents</h4>

*/ ///////////////////////

.hdg {
  color: $warm-black;
  font-family: $font__sans;
  font-style: normal;
  font-weight: normal;
  word-break: break-word;

  &__lg-display {
    font-size: px-to-rem(56px);
    line-height: unitless-line-height(62px, 56px);

    @media #{$md} {
      font-size: px-to-rem(72px);
      line-height: unitless-line-height(80px, 72px);
    }
  }

  &__1 {
    font-size: px-to-rem(46px);
    line-height: unitless-line-height(50px, 46px);

    @media #{$md} {
      font-size: px-to-rem(64px);
      line-height: unitless-line-height(68px, 64px);
    }
  }

  &__2 {
    font-size: px-to-rem(36px);
    line-height: unitless-line-height(40px, 36px);

    @media #{$md} {
      font-size: px-to-rem(56px);
      line-height: unitless-line-height(62px, 56px);
    }
  }

  &__3 {
    font-size: px-to-rem(32px);
    line-height: unitless-line-height(36px, 32px);

    @media #{$md} {
      font-size: px-to-rem(46px);
      line-height: unitless-line-height(52px, 46px);
    }
  }

  &__4 {
    font-size: px-to-rem(28px);
    line-height: unitless-line-height(32px, 28px);

    @media #{$md} {
      font-size: px-to-rem(36px);
      line-height: unitless-line-height(40px, 36px);
    }
  }

  &__5 {
    font-size: px-to-rem(24px);
    line-height: unitless-line-height(28px, 24px);

    @media #{$md} {
      font-size: px-to-rem(26px);
      line-height: unitless-line-height(30px, 26px);
    }
  }

  &__6 {
    font-size: px-to-rem(20px);
    line-height: unitless-line-height(24px, 20px);

    @media #{$md} {
      font-size: px-to-rem(22px);
      line-height: unitless-line-height(26px, 22px);
    }
  }

  &__center {
    text-align: center;
  }

  &__left {
    text-align: left;
  }

  &__right {
    text-align: right;
  }

  &__gray {
    color: $brand__gray;
  }

  &__secondary {
    color: $secondary;
  }

  &__light {
    font-weight: 300;
  }

  &__medium {
    font-weight: 500;
  }
}

.sub-hdg {
  color: $warm-black;
  font-family: $font__sans;
  font-style: normal;
  font-weight: normal;
  word-break: break-word;

  &__2 {
    font-size: px-to-rem(22px);
    line-height: unitless-line-height(28px, 22px);

    @media #{$md} {
      font-size: px-to-rem(28px);
      line-height: unitless-line-height(36px, 28px);
    }
  }
}
