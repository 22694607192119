.wrapper {
  width: 100%;
  margin: 0 auto;

  @media #{$sm} {
    max-width: 43.75em; // 700px
  }

  @media #{$mg} {
    max-width: 48.75em; // 780px
  }

  &__bordered {
    border: 1px solid $gray-100;
  }

  &--padded {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  &--breathing-room {
    padding-top: px-to-rem(48px);
    padding-bottom: px-to-rem(48px);

    @media #{$md} {
      padding-top: px-to-rem(104px);
      padding-bottom: px-to-rem(104px);
    }
  }

  // Widths in ems
  &--96 {
    max-width: 96em;
  }

  &--72 {
    max-width: 72em;
  }

  &--24 {
    max-width: 24em;
  }
}
