.credit-card-graphic {
  position: relative;
  margin: 3% 19% 0 0;//account for space taken up by card backside graphic
}

.credit-card {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  &--flex-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &__row {
      flex-direction: row;
    }
  }
  &--background {
    position: relative;
    border-radius: 16px;
    background-image: url("/assets/img/credit-card/credit-card-front.jpg");
    background-color: $tertiary__shade;
    background-size: cover;
    flex-basis: 100%;
    &::before {
      content: '';
      display: block;
      padding-top: 56.6%; //Maintains the correct aspect ratio of the card shape
    }
    &__back {
      background-image: url("/assets/img/credit-card/credit-card-back.png");
      background-color: $warm-black;
    }
  }
  &--content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 7%;
    &__back {
      padding: 3.67% 11.38%;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }
  &--name {
    align-self: flex-end;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &--logo {
    width: 13.25%;
    height: auto;
  }
  &__back {
    position: absolute;
    left: 23.37%;
    bottom: 5.67%;
    z-index: -1;
  }
}
