@use "sass:math";

$step-count: 3;

.progress-bar {
  font-size: 12px; // TODO change this to a cascade?
  height: 2 * $step-indicator-size;
  position: relative;

  & > * {
    position: absolute;
  }
  &--header {
    display: none;
    justify-content: center;

    @media #{$md} {
      display: flex;
    }
  }

  &--main {
    @media #{$md} {
      display: none;
    }
  }
}

.progress-bar__progress {
  // next three lines to reset default progress styles
  appearance: none;
  -webkit-appearance: none;
  border: 0; // for FF
  height: 2px;
  left: 50%;
  padding: 0 (math.div(100%, $step-count) * 0.5); // x-axis padding equal to half a step to keep progress bar centered behind step circles
  transform: translateX(-50%);
  top: 12px;
  width: 100vw;
  @media #{$sm} {
    padding: 0;
    width: 9 * $step-indicator-size;
  }
  &::-webkit-progress-bar {
    background-color: $gray-200;
  }
  &::-webkit-progress-value {
    background-color: $secondary__tone;
  }
  &::-moz-progress-bar {
    // not sure why but these background-color rules break if combined
    background-color: $secondary__tone;
  }
}


.progress-bar__steps {
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  @media #{$sm} {
    width: 14 * $step-indicator-size;
  }
}

.progress-bar__step {
  flex-basis: math.div(100%, $step-count);
  &--started {
    .progress-bar__step__number {
      background-color: $secondary;
      border-color: $secondary;
      color: $white;
    }
    .progress-bar__step__label {
      color: $secondary;
    }
  }
}

.progress-bar__step__number {
  background-color: $white;
  border: 1px solid $gray;
  border-radius: 50%;
  height: $step-indicator-size;
  line-height: $step-indicator-size;
  margin: 0 auto;
  width: $step-indicator-size;
  text-align: center;

  svg {
    vertical-align: sub;
  }
}

.progress-bar__step__label {
  display: none;
  text-align: center;

  @media #{$md} {
    display: block;
  }
}
