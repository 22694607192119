body {
  font-size: #{$base-font-size}px;
  font-family: $font__sans;
  width: 100%;
  min-height: 100vh;
  display: flex;
  position: relative;
  background-color: $gray__background;
  color: $warm-black;
  -webkit-font-smoothing: antialiased;
}

main {
  height: 100%;
  padding: 1.5rem;
}

@each $size in (1, 2, 3, 4, 5, 6) {
  h#{$size} {
    color: $warm-black;
  }
}

h1 {
  font-size: 2.25rem;
  line-height: 2.25rem;
}

fieldset {
  margin: 0;
  border: none;
  padding: 0;

  legend {
    padding: 0;
  }
}

table {
  table-layout: fixed;
  border: 1px solid $gray__light;
  border-radius: $radio-card-border-radius;
  width: 100%;

  td,
  th {
    padding: 0.5rem 1rem;
  }

  thead {
    padding: 0.5rem 1rem;
    height: 3rem;
  }

  tfoot {
    background: $white;
    height: 3.5rem;
    td,
    th {
      border-top: 1px solid $gray__light;

      &:first-child {
        border-bottom-left-radius: $radio-card-border-radius;
      }

      &:last-child {
        border-bottom-right-radius: $radio-card-border-radius;
      }
    }
  }
}

a.popout {
  // TODO let's review how we want to do this programmatically, ie, with a[href or a[target, rather than with a class
  background: url(/assets/icons/popout.svg) center right no-repeat;
  padding-right: 1rem;
}
