.heading {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start; // if heading wraps lines this keeps the icon at top

  @media #{$md} {
    flex-wrap: nowrap;
  }

  .heading__text {
    line-height: 1.2;
    flex-basis: 100%;
    margin: auto 0; // this vertically centers the heading since we can't do it via align-items
  }

  .heading__icon {
    display: none;
    padding-bottom: 1.25rem;

    @media #{$sm} {
      display: flex;
    }

    @media #{$md} {
      padding-right: 1.25rem;
      padding-bottom: 0;
    }
  }
  .bubble--background {
    width: 3.75rem;
  }
}
