@import 'theme/colors';
@import 'basics/functions';
@import 'theme/variables';
.trustpilot {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  &__heading {
    margin-bottom: px-to-rem(38px);
    text-align: center;
    max-width: px-to-rem(324px);

    @media #{$md} {
      max-width: unset;
      margin-bottom: px-to-rem(48px);
    }
  }

  &__section-link {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }

  &__rating {
    &-container {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: px-to-rem(22px);
      margin-left: px-to-rem(-19px);

      @media #{$md} {
        margin-left: 0;
        margin-bottom: px-to-rem(14px);
      }
    }

    &-stars {
      display: flex;
      justify-content: space-between;
      width: px-to-rem(192px);

      @media #{$md} {
        width: px-to-rem(254px);
      }

      &-icon {
        max-height: px-to-rem(36px);
        max-width: px-to-rem(36px);

        @media #{$md} {
          max-height: px-to-rem(50px);
          max-width: px-to-rem(50px);
        }
      }
    }

    &-text {
      color: $brand__gray;
      font-size: px-to-rem(26px);
      margin-left: px-to-rem(16px);
    }
  }

  &__image {
    @media #{$md} {
      margin-left: px-to-rem(-30px);
      margin-top: px-to-rem(2px);
    }
  }

  &__reviews {
    display: flex;
    flex-direction: row;
    margin-bottom: 0;
    padding-left: 0;

    @media #{$md} {
      margin-top: px-to-rem(11px);
      width: px-to-rem(222px);
    }

    li {
      font-size: px-to-rem(18px);
      font-weight: 400;
      margin-left: px-to-rem(28px);

      &:first-child {
        list-style-type: none;
        margin-left: 0;
      }

      @media #{$md} {
        font-size: px-to-rem(17px);
      }
    }

    &-rating {
      color: $brand__gray;
      font-weight: 500;
    }
    &-count-link {
      color: $brand__gray;
      text-decoration: underline;
    }
  }
}

.trustpilot-widget {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
}