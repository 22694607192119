.alert-bar {
  position: fixed;
  padding: .5rem 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $primary__tone;

  &__info {
    background-color: $tertiary__tone;
  }
}