.alert {
  display: block;
  padding: 1rem;
  border: 2px solid;
  border-left-width: 5px;
  border-radius: .25rem;

  &__error {
    background-color: $white;
    border-color: $brand__red__tone;
  }

  &__warning {
    background-color: $primary__light;
    border-color: $primary;
  }

  &__info {
    background-color: $white;
    border-color: $tertiary__tone;
  }
}
