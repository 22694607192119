// @todo do we want this to be shared? I moved it over only for the header and footer but it could be used more generally
.h-list {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  list-style: none;
  flex-wrap: wrap;
  padding-left: 0;
}

.h-list--padded {
  padding: 1rem 0;
}

.h-list--nowrap {
  flex-wrap: nowrap;
}

.h-list--top {
  align-items: flex-start;
}

.h-list--middle {
  align-items: center;
}

.h-list--bottom {
  align-items: flex-end;
}

.h-list--right {
  justify-content: flex-end;
}

.h-list:not(.h-list--bare) > * {
  margin-right: 1rem;
}

.h-list:not(.h-list--bare) > :last-child {
  margin-right: 0;
}

.h-list--center {
  justify-content: center;
}

.h-list--space-between {
  justify-content: space-between;
}
/**
  * Useful for getting text-overlow: ellipsis to work
  */
.h-list--overflow-hidden {
  overflow: hidden;
}

@for $i from 0 through 10 {
  .h-list--x#{$i}:not(.h-list--bare) > * {
    margin-right: #{$i}rem;
  }
}

@media #{$sm} {
  .h-list\@small {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    flex-wrap: wrap;
    list-style: none;
    padding-left: 0;
  }

  .h-list--top\@small {
    align-items: flex-start;
  }

  .h-list--center\@small {
    justify-content: center;
  }

  .h-list--bottom\@small {
    align-items: flex-end;
  }

  .h-list--right\@small {
    justify-content: flex-end;
  }

  .h-list\@small:not(.h-list--bare) > * {
    margin-right: 1rem;
  }

  .h-list\@small:not(.h-list--bare) > :last-child {
    margin-right: 0;
  }

  .h-list--middle\@small {
    align-items: center;
  }

  @for $i from 2 through 10 {
    .h-list--x#{$i}\@small:not(.h-list--bare) > * {
      margin-right: #{$i}rem;
    }
  }
}

@media #{$md} {
  .h-list\@medium {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    flex-wrap: wrap;
    list-style: none;
    padding-left: 0;
  }

  .h-list--top\@medium {
    align-items: flex-start;
  }

  .h-list--center\@medium {
    justify-content: center;
  }

  .h-list--bottom\@medium {
    align-items: flex-end;
  }

  .h-list--right\@medium {
    justify-content: flex-end;
  }

  .h-list\@medium:not(.h-list--bare) > * {
    margin-right: 1rem;
  }

  .h-list\@medium:not(.h-list--bare) > :last-child {
    margin-right: 0;
  }

  .h-list--middle\@medium {
    align-items: center;
  }

  @for $i from 2 through 10 {
    .h-list--x#{$i}\@medium:not(.h-list--bare) > * {
      margin-right: #{$i}rem;
    }
  }
}
