.is-visible {
  display: initial;
}

.is-hidden {
  display: none;
}

.is-visually-hidden {
  @include sr-only();
}

.no-padding {
  padding: 0;
}

@media #{$sm} {
  .is-hidden\@small {
    display: none;
  }

  .is-visually-hidden\@msmall {
    @include sr-only();
  }

  .is-visible\@small {
    display: initial;
  }
}

@media #{$md} {
  .is-hidden\@medium {
    display: none;
  }

  .is-visually-hidden\@medium {
    @include sr-only();
  }

  .is-visible\@medium {
    display: initial;
  }
}

.route-animation-container {
  position: relative;
}

// Marketing-like landing pages have a white background
// and need to negate the space added from within the header
.negate-header-margin {
  margin-top: px-to-rem(-81px);

  @media #{$md} {
    margin-top: px-to-rem(-24px);
  }
}

.full-width-mobile {
  margin-right: px-to-rem(-24px);
  margin-left: px-to-rem(-24px);

  @media #{$md} {
    margin-right: unset;
    margin-left: unset;
  }
}

.bg-secondary {
  background-color: $secondary;
}

.bg-tertiary {
  background-color: $tertiary;
}

.bg-white {
  background-color: $white;
}

.bg-primary {
  background-color: $primary;
}

.bg-primary-light {
  background-color: $primary__light;
}
