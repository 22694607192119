.split {
  display: flex;
  justify-content: space-around;
  align-items: center;
  .toggle {
    margin-top: 0;
  }
}

.split__top {
  align-items: flex-start;
}

.split__between {
  justify-content: space-between;
  .split--left {
    text-align: left;
  }

  .split--right {
    text-align: right;
  }
}

.split__with-middle {
  .split--left,
  .split--right {
    width: calc(45% - 0.5em);
  }

  .split--or {
    width: 10%;
  }
}

.split__flexible {
  > * {
    width: auto;
  }
}

.split--left,
.split--right {
  flex: 1 1 auto;
}

.split--right {
  margin-left: 1em;
}