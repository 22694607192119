.v-list {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

.v-list__left {
  align-items: flex-start;
}

.v-list__center {
  align-items: center;
}

.v-list__right {
  align-items: flex-end;
}

.v-list__bordered > *:not(:last-child) {
  border-bottom: 1px solid $gray-200;
}

.v-list__bordered--dashed > *:not(:last-child) {
  border-bottom: 1px dashed $gray-200;
}

.v-list:not(.v-list--no-margin) > * {
  margin-bottom: 1rem;
}

.v-list--no-block-margin {
  margin-block: 0;
}

.v-list__half > * {
  margin-bottom: 0.5rem;
}

.v-list > :last-child {
  margin-bottom: 0;
}

@for $i from 1 through 10 {
  .v-list__x#{$i} > * {
    margin-bottom: #{$i}rem;
  }
}

.v-list__x1half > * {
  // TODO check with design if we really need this
  margin-bottom: 1.5rem;
}

@media #{$md} {
  .v-list\@medium {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    list-style: none;
    margin-left: 0;
    padding-left: 0;
  }
  
  .v-list__center\@medium {
    align-items: center;
  }
  
  .v-list__right\@medium {
    align-items: flex-end;
  }
  
  .v-list__bordered\@medium > *:not(:last-child) {
    border-bottom: 1px solid $gray-200;
  }

  .v-list__bordered--dashed\@medium > *:not(:last-child) {
    border-bottom: 1px dashed $gray-200;
  }
  
  .v-list\@medium > * {
    margin-bottom: 1rem;
  }
  
  .v-list\@medium > :last-child {
    margin-bottom: 0;
  }
  
  @for $i from 1 through 10 {
    .v-list__x#{$i}\@medium > * {
      margin-bottom: #{$i}rem;
    }
  }
}
