@mixin tooltip-left {
  left: unset;
  right: -13px;

  &:before,
  &:after {
    left: unset;
    right: 0.8rem;
  }
}

.tooltip__container {
  bottom: 0;
  left: 0.375rem;
  position: absolute;
  width: calc(100% - 1.5rem); // subtracting width of padding

  &__trigger {
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: block;
    max-width: 1.5rem; // width of icon
    padding: 0;

    svg {
      pointer-events: none;
    }
  }

  &--small {
    .tooltip {
      top: 26px;
      left: -9px;
    }
  }
}

.input-textbox--label .tooltip__container {
  display: inline-block;
  position: relative;
  width: auto;
  left: 2px;
  bottom: -1px;

  svg {
    width: 1rem;
  }
}

.tooltip {
  background-color: $primary__light;
  border: 1px solid $secondary;
  border-radius: 0.25rem;
  display: none;
  left: -8px;
  position: absolute;
  pointer-events: none;
  padding: px-to-rem(24px);
  text-align: left;
  top: 31px;
  width: 14rem;

  &__text {
    color: $brand__gray;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  &.active {
    display: block;
    pointer-events: all;
    z-index: 1000;
  }

  .row .col:last-of-type:not(:only-of-type) & {
    // this targets tooltips in the last column of a grid and flips the container so it doesn't go outside parent bounds, eg, in a modal
    @include tooltip-left;
  }

  &__close {
    background: none;
    border: none;
    color: $brand__gray;
    cursor: pointer;
    position: absolute;
    right: 7px;
    top: 11px;

    svg {
      height: 12px;
      width: 12px;
      position: absolute;
      right: 0;
      top: 0;
    }

    &:focus-visible {
      outline: none;

      svg {
        outline: auto 1px;
        outline: -webkit-focus-ring-color auto 1px;
      }
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: -7px;
    left: 0.8rem;
    width: 14px;
    height: 14px;
    background-color: $primary__light;
    clip-path: polygon(100% 0, 0 0, 100% 100%);
    transform: rotate(-45deg);
    z-index: 1000;
    border-top: 1px solid $secondary;
    border-right: 1px solid $secondary;
    border-radius: 0.25rem;
  }
  &:after {
    content: '';
    position: absolute;
    top: -5px;
    left: 0.8rem;
    width: 11px;
    height: 11px;
    background-color: $primary__light;
    clip-path: polygon(100% 0, 0 0, 100% 100%);
    transform: rotate(-45deg);
    z-index: 1000;
    border-radius: 0.25rem;
  }
}

@media (max-width: 400px) {
  .tooltip {
    width: 14em;

    &--left & {
      @include tooltip-left;
    }
  }
}

.tooltip--inline {
  position: relative;
  padding-left: 0.25rem;

  .tooltip__container {
    position: static;
    display: inline;
    &__trigger {
      display: inline-block;
    }

    kin-svg {
      vertical-align: text-bottom;
    }
  }
}
