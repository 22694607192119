$menu-button-built-in-spacing: px-to-rem(10px);

.header-group {
  @media #{$md} {
    position: sticky;
    top: 0;
    z-index: 100;
    align-self: flex-start;
    width: 100%;
  }
}

.header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: center;
  height: $header-height;
  padding-right: px-to-rem(24px);
  padding-left: px-to-rem(24px);
  background-color: $white;
  border-bottom: 1px solid $gray__tone;

  @media #{$sm} {
    padding-right: px-to-rem(112px);
    padding-left: px-to-rem(112px);
    grid-template-columns: 2fr 1fr;
  }

  &__left {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }

  &__right {
    width: 100%;
  }


  &__back {
    @media #{$md} {
      z-index: 1000;
      display: block;
      position: fixed;
      top: px-to-rem(130px);
      left: px-to-rem(16px);
    }
  }

  &__logos {
    grid-column-start: 2;

    @media #{$md} {
      grid-column-start: 1;
      text-align: left;
    }
  }

  &__main-logo {
    height: px-to-rem(48px);
  }

  &__phone {
    .text {
      .is-visible\@small {
        line-height: $minimum-touch-target;
      }

      &:hover,
      &:focus {
        color: $secondary;

        .icon {
          stroke: $secondary;
        }
      }
    }
  }
}
