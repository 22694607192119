// TODO: Refactor specificity once specificity app-wide is toned down.
// Specificity Calculator: https://specificity.keegan.st/
// Example: body.funnel input:disabled > .k-datepicker .k-datepicker__body__table button:disabled
// due to the first rule having an !important.
@use 'basics/functions';
@use 'theme/colors';
@use 'theme/variables';

@mixin pill-selection-indicator {
  border-radius: 19px;
  height: 35px;
  padding: 5px 15px;
}

.datepicker__container {
  position: relative;
}

.datepicker__container .datepicker__container__trigger {
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  border: 0;
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  cursor: pointer;
  
  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
}

.datepicker__container .k-datepicker {
  position: absolute;
  outline: none;
  right: -6px;
  top: 3.5em;
  z-index: 10000000;
}

.k-datepicker {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 20em;
  min-height: 16em;
  border: 1px solid colors.$secondary;
  box-shadow: 0px 4px 12px rgba(33, 33, 33, 0.12);
}

.k-datepicker, .k-datepicker__header {
  background-color: colors.$white;
  border-radius: 7.97158px;
}

.k-datepicker:before {
  content: "";
  position: absolute;
  top: -6px;
  right: functions.px-to-rem(19px);
  width: 16px;
  height: 16px;
  background-color: colors.$white;
  clip-path: polygon(100% 0, 0 0, 100% 100%);
  transform: rotate(-45deg);
  z-index: 0;
  border: 1px solid colors.$white;
}

.k-datepicker:after {
  content: "";
  position: absolute;
  top: -8px;
  right: functions.px-to-rem(19px);
  width: 16px;
  height: 16px;
  background-color: colors.$white;
  clip-path: polygon(100% 0, 0 0, 100% 100%);
  transform: rotate(-45deg);
  z-index: 0;
  border: 1px solid colors.$secondary;
}

.k-datepicker.is-active {
  display: block;
}

.k-datepicker .k-datepicker__header {
  padding: 2.5em 2em 0;
}

.k-datepicker .k-datepicker__header button,
.k-datepicker__body tfoot button {
  appearance: none;
  -webkit-appearance: none;
  border: 0;
  padding: 0;
  margin: 0;
  font-size: 12px;
  font-family: variables.$font__sans__bold, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  background-color: transparent;
}

.k-datepicker button.k-datepicker__header__toggle {
  color: colors.$secondary;
  font-size: 14px;
}

.k-datepicker button.k-datepicker__header__button {
  width: 40px;
  height: 40px;
  position: relative;
  background-color: colors.$white;
  appearance: none;
  -webkit-appearance: none;
  border: 0;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.k-datepicker button.k-datepicker__header__toggle,
.k-datepicker button.k-datepicker__header__button {
  cursor: pointer;
}
.k-datepicker__body table {
  border: none;
}

.k-datepicker .k-datepicker__body__table {
  padding: 1.5em;
  width: 100%;
}

.k-datepicker .k-datepicker__body__table tr th,
.k-datepicker .k-datepicker__body__table tr td {
  text-align: center;
}

.k-datepicker .k-datepicker__body__table tr th {
  padding-bottom: 0.5em;
  font-size: 14px;
  font-family: variables.$font__sans__bold, sans-serif;
  font-weight: 700;
  background-color: colors.$white;
}

.k-datepicker .k-datepicker__body__table tr td {
  font-size: 14px;
  padding: 0;
}

.k-datepicker .k-datepicker__body__table button {
  appearance: none;
  -webkit-appearance: none;
  border: 0;
  background: none;
  color: colors.$brand__gray;
  min-width: 34px;
  min-height: 34px;
  padding: 0.5em;
  cursor: pointer;
}

.k-datepicker .k-datepicker__body__table tbody button:not(:disabled) {
  border-radius: 50%;
  border: 2px solid transparent;
  height: 34px;
  width: 34px;
  &:hover {
    border-color: colors.$secondary;
  }
}

.k-datepicker .k-datepicker__body .k-datepicker__body__table--decade button:not(:disabled),
.k-datepicker .k-datepicker__body .k-datepicker__body__table--month  button:not(:disabled) {
  @include pill-selection-indicator();
  border: 2px solid transparent;
  height: unset;
  width: unset;
  &:hover {
    border-color: colors.$secondary;
    min-height: unset;
  }
}

.datepicker__container__trigger svg {
  pointer-events: none;
  vertical-align: middle;
}

.k-datepicker .k-datepicker__body__table tfoot button {
  min-height: 20px;
  min-width: 100%;
}

.k-datepicker .k-datepicker__header .h-list .h-list span, 
.k-datepicker .k-datepicker__body__table tfoot button span {
  border-bottom: 2px solid transparent;
  &:hover {
    border-color: colors.$secondary;
  }
}

// TODO: Fix the specificity once forms.scss is fixed.
.k-datepicker .k-datepicker__body__table button.in-active:disabled,
.k-datepicker .k-datepicker__body__table button.in-active.disabled:disabled {
  cursor: default;
  background-color: transparent !important;
  border: 2px solid transparent;
  color: colors.$gray;
  height: 34px;
}

.k-datepicker .k-datepicker__body__table--decade,
.k-datepicker .k-datepicker__body__table--month {
  td {
    width: 33.3%;
  }
  button {
    min-height: 50px;
    min-width: 33%;
    &.selected {
      @include pill-selection-indicator();
      min-height: unset;
    }
  }
}

.k-datepicker .k-datepicker__body__table .today {
  font-family: variables.$font__sans__bold, sans-serif;
  font-weight: 700;
  color: colors.$secondary;
}

.k-datepicker .k-datepicker__body__table .selected {
  background-color: colors.$secondary;
  border-radius: 50%;
  color: colors.$white;
}
