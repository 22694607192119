//mobile animation
.menu-trigger {
  @include header-button;

  &::after {
    position: absolute;
    background-color: $warm-black;
    content: '';
    height: 2px;
    top: 16px;
    left: 9px;
    width: 24px;
    transition: transform 0.2s ease-in-out, top ease-in-out;
    will-change: transform;
  }
  &::before {
    position: absolute;
    background-color: $warm-black;
    content: '';
    height: 2px;
    top: 26px;
    left: 9px;
    width: 24px;
    transition: transform 0.2s ease-in-out, top ease-in-out;
    will-change: transform;
  }
  &.is-active {
    &::after {
      position: absolute;
      background-color: $warm-black;
      content: '';
      height: 2px;
      top: 21px;
      left: 9px;
      width: 24px;
      transform: rotate(45deg);
      transition: transform 0.2s ease-in-out, top ease-in-out;
      will-change: transform;
    }
    &::before {
      position: absolute;
      background-color: $warm-black;
      content: '';
      height: 2px;
      top: 21px;
      left: 9px;
      width: 24px;
      transform: rotate(-45deg);
      transition: transform 0.2s ease-in-out, top ease-in-out;
      will-change: transform;
    }
  }
}

.menu {
  display: none;
}

.menu.is-active {
  display: block;
  position: absolute;
  top: 5.5rem;
  left: 0;
  right: 0;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary__light;
  filter: drop-shadow(0px 2px 3px rgba(33, 33, 33, 0.12));

  .menu__inner {
    max-width: $header-width;
    width: 100%;
    padding: 2rem;
  }
}