@use "theme/colors";

.hint {
  font-size: 0.875rem;
  color: colors.$gray-900;
  display: flex;
  flex-direction: row;
  margin: 0.5rem 0;

  .icon {
    margin-right: 0.5rem;
  }
}
