@use "sass:math";

$base-button-size: 24px;
$base-switch-width: $base-button-size * 2.5;

kin-form-toggle {
  fieldset {
    display: flex;
    > * {
      margin-top: 1rem;
    }
  }
}

.toggle {
  position: relative;
  display: inline-block;

  [type="checkbox"] {
    top: auto;

    + label {
      height: 100%;
      cursor: pointer;
      display: inline-block;
      position: relative;
      padding: 8px 80px 8px 0;
    }

    + label::before,
    + label::after {
      top: 12px;
      content: "";
      position: absolute;
      height: $base-button-size;
      transition: all 0.25s ease;
      cursor: pointer;
      right: 0;

      @media #{$sm} {
        @include vertically-center;
      }
    }

    + label::before {
      width: $base-switch-width;
      border: 3px solid $gray-600;
      background: $gray-600;
      border-radius: math.div($base-button-size * 2, 3);
    }

    + label::after {
      background-color: #fff;
      background-position: center center;
      border-radius: 50%;
      width: $base-button-size;
      border: 2px solid $gray-600;
      right: $base-switch-width - $base-button-size;
    }

    &:focus {
      + label::before {
        border-color: $secondary;
        background-color: $secondary;
      }

      + label::after {
        border-color: $secondary;
      }
    }

    &:checked {
      + label::after {
        border-color: $primary__bold;
        color: $primary__bold;
        right: 0;
      }

      + label::before {
        background-color: $primary__bold;
        border-color: $primary__bold;
      }

      ~ .toggle-label {
        color: $black;
        right: 22px;
        transition: all 0.15s ease-out;
      }
    }
  }

  .toggle-label {
    top: 16px;
    position: absolute;
    pointer-events: none;
    width: $base-switch-width - $base-button-size;
    right: 3px;
    font-size: 11px;
    text-transform: uppercase;
    text-align: center;
    color: $white;
    @media #{$sm} {
      @include vertically-center;
    }
  }
}

/* Reduced motion */
@media screen and (prefers-reduced-motion: reduce) {
  .toggle [type="checkbox"] + label::before,
  .toggle [type="checkbox"] + label::after,
  .toggle [type="checkbox"]:checked ~ .toggle-label {
    transition: none;
  }
}

