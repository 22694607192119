.external--address-autocomplete {
  position: relative;

  input:focus ~ .input-textbox--label,
  input:not(:placeholder-shown) ~ .input-textbox--label {
    bottom: 2rem;
    font-size: .875rem;
    color: $secondary;
  }


  .aa--address-input {
    @include input-textbox(44px);
    width: 100%;
  }
}

// doc for these pac classes https://developers.google.com/maps/documentation/javascript/places-autocomplete#style-autocomplete
.pac-icon {
  display: none;
}

.pac-container {
  @include autocomplete-list;
}

.pac-item {
  @include autocomplete-item;
}
