.autocomplete {
  position: relative;

  &__list {
    @include autocomplete-list;
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 1000;
    width: 100%;

    & ul {
      padding: 0;
      margin: 0;
      list-style: none;
    }
  
    &-item {
      @include autocomplete-item;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }
}
