.header-circle {
  min-width: 100vw;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  margin: 0;
  overflow: hidden; // Safari doesn't support clip
  overflow: clip;

  &__primary {
    .header-circle--background {
      background-color: $tertiary__tone;
      overflow-x: hidden;
    }
  }

  &--background {
    position: absolute;
    left: -50%;
    top: -10rem;
    width: 200vw;
    height: 100%;
    z-index: -1;
    clip-path: ellipse(68.46% 57.57% at 50.26% 10.36%);
  }
}

@media #{$sm} {
  .header-circle {
    filter: unset;

    .header-circle--background {
      left: unset;
      top: -8rem;
      width: 100%;
      height: 100%;
    }
  }
}
