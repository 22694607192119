.input-textbox,
.input-password,
.input-textbox--address-kinterface {
  position: relative;
  display: flex;
  flex-direction: column;
  width: auto;
  font-weight: 300;
  &--label {
    @include label-as-placeholder-text;
    position: absolute;
    font-weight: inherit;
    line-height: 1;
    pointer-events: none;
    transition: all .125s ease-in-out;
  }

  &--input {
    @include input-textbox(#{$base-unit}ch);

    &:focus {
      background-color: $primary__light;
    }

    &:focus ~ .input-textbox--label,
    // TODO we may beed to get more specific here than just [disabled] in case we ever initialize a new form as disabled
    // this next line sets the label position on fields which are part of a form we disable on change for API changes
    &[disabled] ~ .input-textbox--label,
    &__active ~ .input-textbox--label,
    &__always-activate-label ~ .input-textbox--label,
    &:not(:placeholder-shown) ~ .input-textbox--label {
      bottom: 3rem;
      font-size: .875rem;
      color: $secondary;
    }
    &[readonly] ~ .input-textbox--label,
    &[disabled] ~ .input-textbox--label {
      @include disabled-input-label;
    }
  }

  &--textarea {
    @include input-textbox(auto);
  }

  &--select {
    @include input-textbox(#{$base-unit}ch);
    appearance: none;

    & ~ .input-textbox--icon {
      position: absolute;
      pointer-events: none;
      right: .5rem;
      bottom: 3rem;
      width: .875rem;
      height: .875rem;
      border-bottom: 1px solid $secondary;
      border-right: 1px solid $secondary;
      transform: rotate(45deg) scaleX(1.1);
    }

    &:focus {
      background-color: $primary__light;
    }

    &:focus ~ .input-textbox--label,
    &[disabled] ~ .input-textbox--label,
    &:valid ~ .input-textbox--label {
      bottom: 3rem;
      font-size: .875rem;
      color: $secondary;
    }

    &[readonly] ~ .input-textbox--label,
    &[disabled] ~ .input-textbox--label {
      @include disabled-input-label;
    }
  }

  &--file-field {
    @include input-textbox(auto);
    border: 1px dashed $gray;
  }

  .ng-invalid.ng-dirty.ng-touched,
  .kin-async-invalid & .ng-invalid.ng-dirty {
    border-bottom-color: $brand__red;

    &~.input-textbox--label {
      color: $brand__red;
    }
  }
}

kin-form-select .input-textbox--label,
kin-form-datepicker .input-textbox--label {
  margin-right: 4rem;
}

@media #{$sm} {
  .input-textbox,
  .input-password,
  .input-textbox--address-kinterface {
    &--input {
      @include input-textbox($base-unit * 16px);
    }
    &--select {
      @include input-textbox($base-unit * 16px);

      & ~ .input-textbox--icon {
        bottom: 1rem;
      }

      &:focus ~ .input-textbox--label,
      &[disabled] ~ .input-textbox--label,
      &:valid ~ .input-textbox--label {
        bottom: 3rem;
        font-size: .875rem;
        color: $secondary;
      }

      &[readonly] ~ .input-textbox--label,
      &[disabled] ~ .input-textbox--label {
        @include disabled-input-label;
      }
    }
  }

  .input--constrained {
    margin: 0 auto;
    max-width: 25em; // 400px
  }

  .input--never-activate-label *:not(.input-textbox--input__always-activate-label, :focus) ~ .input-textbox--label,
  .input--never-activate-label *[disabled]:not(.input-textbox--input__always-activate-label, :focus) ~ .input-textbox--label {
    @include label-as-placeholder-text;
  }
}
