/* /////////////////////// Link for external links
text link styles: apply to <a> elements. This style is for any link that directs outside the site that opens a new tab. To maintain a11y pattens, additional information in this pattern communicates to users that they will be leaving the kin website is added in the '.sr-only' class.

rel="noopener" prevents the new page from being able to access the window.opener property and ensures it runs in a separate process.

example:
<a href="https://www.trustpilot.com/review/kin.com" class="external-link" target="_blank" rel="noopener">
  Trustpilot
  <span class="sr-only">opens in new window</span>
</a>

*/ ///////////////////////

.external-link {
  &::after {
    content: '';
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMiAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuMTQ4MTkgNC43MDM2MUg0QzIuMzQzMTUgNC43MDM2MSAxIDYuMDQ2NzYgMSA3LjcwMzYxVjE4QzEgMTkuNjU2OSAyLjM0MzE1IDIxIDQgMjFIMTQuMjk2NEMxNS45NTMyIDIxIDE3LjI5NjQgMTkuNjU2OSAxNy4yOTY0IDE4VjEyLjg1MTgiIHN0cm9rZT0iIzAwNzA1NSIgc3Ryb2tlLXdpZHRoPSIyIi8+CjxwYXRoIGQ9Ik0xMi4xMTExIDFIMjFWOS44ODg5NCIgc3Ryb2tlPSIjMDA3MDU1IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTIwLjI1OTUgMS43NDAzTDEwLjE4NTQgMTEuODE0NCIgc3Ryb2tlPSIjMDA3MDU1IiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==');
    background-size: px-to-rem(10px);
    background-repeat: no-repeat;
    background-position: center center;
    margin: 0 0.125em;
    display: inline-block;
    height: px-to-rem(10px);
    width: px-to-rem(10px);
    opacity: 0.5;
  }
  &:focus::after,
  &:hover::after {
    opacity: 1;
  }
}
