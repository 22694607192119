.address-bar {
  padding: 1rem;
  border-bottom: 1px solid var(--color-neutral-300);
  background-color: var(--color-primary-100);
  text-align: center;

  p {
    margin: 0;
    font-weight: var(--font-weight-bold);
  }
}
