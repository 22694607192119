// This is a temporary measure while we move *base* flexbox layout grids to be the child of a .wrapper
.grid--fluid {
  margin-right: -.5rem;
  margin-left: -.5rem;
  @media #{$lg} {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.grid--flush {
  margin-right: -.5rem;
  margin-left: -.5rem;
}

.grid--alternating-gutter {
  .col:nth-of-type(odd) {
    padding-right: 1.5rem
  }
  .col:nth-of-type(even) {
    padding-left: 1.5rem
  }
}

.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;

  &--dashed {
    border-bottom: 1px dashed $gray;
    &-except-last:last-child {
      border-bottom: 1px transparent;
    }
    &-lighter {
      border-bottom-color: $gray-200;
    }
  }

  &--solid {
    border-bottom: 1px solid $gray-200;
  }

  &__emphasize {
    .input-textbox {
      &::before {
        position: absolute;
        content: '$';
        font-size: 2.25rem;
        font-weight: 300;
        top: 2.25rem;
      }

      &--input {
        border-bottom: none;
        color: $warm-black;
        font-size: 4.5rem;
        height: auto;
        line-height: 1;
        padding-top: 2rem;
        padding-left: 1.5rem;
      }

      & .input-textbox--label {
        bottom: unset;
        top: 0;
        font-size: 1rem;
      }
    }
  }

  &--padding-2x {
    .col {
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }
}

.row.reverse {
  flex-direction: row-reverse;
}

.col {
  display: flex;
  flex-direction: column;
  width: 100%;
  + .col {
    margin-top: 2rem;
    @media #{$sm} {
      margin-top: unset;
    }
  }
}

.col.reverse {
  flex-direction: column-reverse;
}

@mixin grid($name) {
  .col-#{$name},
  .col-#{$name}-1,
  .col-#{$name}-10,
  .col-#{$name}-11,
  .col-#{$name}-12,
  .col-#{$name}-2,
  .col-#{$name}-3,
  .col-#{$name}-4,
  .col-#{$name}-5,
  .col-#{$name}-6,
  .col-#{$name}-7,
  .col-#{$name}-8,
  .col-#{$name}-9,
  .col-#{$name}-offset-0,
  .col-#{$name}-offset-1,
  .col-#{$name}-offset-10,
  .col-#{$name}-offset-11,
  .col-#{$name}-offset-12,
  .col-#{$name}-offset-2,
  .col-#{$name}-offset-3,
  .col-#{$name}-offset-4,
  .col-#{$name}-offset-5,
  .col-#{$name}-offset-6,
  .col-#{$name}-offset-7,
  .col-#{$name}-offset-8,
  .col-#{$name}-offset-9 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .col-#{$name} {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-#{$name}-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-#{$name}-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-#{$name}-3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-#{$name}-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-#{$name}-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-#{$name}-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-#{$name}-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-#{$name}-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-#{$name}-9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-#{$name}-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-#{$name}-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-#{$name}-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-#{$name}-offset-0 {
    margin-left: 0;
  }
  .col-#{$name}-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-#{$name}-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-#{$name}-offset-3 {
    margin-left: 25%;
  }
  .col-#{$name}-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-#{$name}-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-#{$name}-offset-6 {
    margin-left: 50%;
  }
  .col-#{$name}-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-#{$name}-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-#{$name}-offset-9 {
    margin-left: 75%;
  }
  .col-#{$name}-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-#{$name}-offset-11 {
    margin-left: 91.66666667%;
  }
  .start-#{$name} {
    justify-content: flex-start;
  }
  .center-#{$name} {
    justify-content: center;
  }
  .end-#{$name} {
    justify-content: flex-end;
  }
  .top-#{$name} {
    align-items: flex-start;
  }
  .middle-#{$name} {
    align-items: center;
  }
  .bottom-#{$name} {
    align-items: flex-end;
  }
  .stretch-#{$name} {
    align-items: stretch;
  }
  .around-#{$name} {
    justify-content: space-around;
  }
  .between-#{$name} {
    justify-content: space-between;
  }
  .first-#{$name} {
    order: -1;
  }
  .last-#{$name} {
    order: 1;
  }
}

$sizes: ("xs": "none", "sm": $small, "md": $medium, "lg": $large, "xl": $x-large);

@each $name, $size in $sizes {
  @if $size == "none" {
    @include grid($name)
  } @else {
    @media only screen and (min-width: $size) {
      @include grid($name)
    }
  }
}

$spans: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

@each $span in $spans {
  .col__span--#{$span} {
    grid-column: span $span;
  }
}

.col__span--full {
  grid-column: 1 / -1;
}

div.gutter {
  &--1hx {
    padding-right: 0.5rem;
    @media #{$md} {
      padding-right: 1.5rem;
    }
  }
}
