.icon {
  display: inline-block;
  max-width: 1.5rem;
  max-height: 1.5rem;
  width: 100%;

  svg {
    max-width: inherit;
    max-height: inherit;
  }
  &.icon__block {
    //display: block is nice when icons need not be affected by line height or don't need to be inline with text
    display: block;
  }
  &.icon__secondary {
    svg {
      fill: $secondary;
    }
  }
  &.icon__xsmall {
    max-width: 0.825rem;
    max-height: 0.825rem;
    width: 100%;
    &--fixed {
      width: 0.825rem;
      height: 0.825rem;
    }
  }
  &.icon__small {
    max-width: 1rem;
    max-height: 1rem;
    width: 100%;
    &--fixed {
      width: 1rem;
      height: 1rem;
    }
  }
  &.icon__medium {
    max-width: 1.5rem;
    max-height: 1.5rem;
    width: 100%;
    &--fixed {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  &.icon__large {
    max-width: 2rem;
    max-height: 2rem;
    width: 100%;
    &--fixed {
      width: 2rem;
      height: 2rem;
    }
  }
  &.icon__xlarge {
    max-width: 3rem;
    max-height: 3rem;
    width: 100%;
    &--fixed {
      width: 3rem;
      height: 3rem;
    }
  }
  &.icon__xxlarge {
    max-width: 4.5rem;
    max-height: 4.5rem;
    width: 100%;
    &--fixed {
      width: 4.5rem;
      height: 4.5rem;
    }
  }
  &.icon__xxxlarge {
    max-width: 6.5rem;
    max-height: 6.5rem;
    width: 100%;
    &--fixed {
      width: 6.5rem;
      height: 6.5rem;
    }
  }
  &.icon__scale-with-font {
    max-width: 1em;
    max-height: 1em;
    width: 1em;
    height: 1em;
  }
  &.icon__fit {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
  }
  &.icon__text-inline {
    vertical-align: middle;
    &--leading {
      margin-right: 0.5rem;
      &-close {
        margin-right: 0.25rem;
      }
    }
    &--trailing {
      margin-left: 0.5rem;
      &-close {
        margin-left: 0.25rem;
      }
    }
  }
  &.icon__error {
    path,
    line,
    polyline {
      stroke: $brand__red__tone;
    }
    &:hover,
    &:focus-within {
      path,
      line,
      polyline {
        fill: $brand__red;
      }
    }
  }
  &.icon__brand__red-fill {
    fill: $brand__red;
  }
  &.icon__gray {
    path,
    line,
    polyline {
      fill: $gray-600;
    }
    &:hover,
    &:focus-within {
      path,
      line,
      polyline {
        fill: $black;
      }
    }
  }
  &.icon__gray-outline {
    stroke: $brand__gray;
    stroke-width: 1.5;
  }
  &.icon__white {
    stroke: $white;
    path,
    line,
    polyline {
      fill: $white;
    }
    &:hover,
    &:focus-within {
      path,
      line,
      polyline {
        fill: $white;
      }
    }
  }
  &.icon__white-fill {
    fill: $white;
  }
  &.icon__tertiary-tone {
    fill: $tertiary__tone;
  }
  &.icon--circled {
    background-color: $primary;

    &.icon__background__tertiary {
      background-color: rgba($tertiary__tone, 0.05);
    }

    &.icon__error {
      background-color: $brand__red;
    }

    border-radius: 50%;
    padding: #{$base-unit * 2}px;

    // Add some extra space on mobile so the circle doesn't visually merge with the header
    margin-top: px-to-rem(40px);

    @media #{$md} {
      margin-top: 0px;
    }

    use {
      // avoids clipping from the border-radius
      transform: scale(0.8);
      transform-origin: center;
    }
  }

}

.icon-wrapper {
  border: 1px solid;
  max-height: $minimum-touch-target;
  max-width: $minimum-touch-target;
  &__circle {
    border-color: $brand__gray;
    border-radius: 50%;
    overflow: visible;
    padding: .50rem;
    border: none;
    .icon {
      display: block;
      stroke: $secondary;
      stroke-width: 1.5;
    }
    &:hover {
      border-color: $secondary;
      .icon {
        stroke: $secondary;
      }
    }
  }
}

.icon-background {
  border: 1px solid;
  min-height: $minimum-touch-target;
  min-width: $minimum-touch-target;
  border-radius: 50%;
  &--primary {
    background-color: $primary;
    border-color: $primary;
  }
  &--center {
    display: flex;
    justify-content: center;
    padding: .5rem;
  }
}
