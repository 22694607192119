.badge {
  display: inline-block;
  padding: .25rem .5rem;
  font-weight: 600;
  border-radius: 4px;
  margin: .25rem;
}

.badge__astra {
  background-color: $astra;
  color: $black;
}

.badge__primary {
  background-color: $primary;
  color: $black;
}