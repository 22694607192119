.loader:not(.loader__relative) {
  .loader--overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.4);
    backdrop-filter: blur(5px);
  }

  .loader--spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .loader--message {
    position: fixed;
    top: 50%;
    left: 50%;
    text-shadow: 1px 1px 3px #000;
    transform: translate(-50%, -125px);
    font-size: 2rem;
    color: $white;
  }
}

.loader__relative {
  margin: 0 auto;
  margin-top: 3rem;
  position: relative;
  @media #{$mg} {
    margin-top: 0;
  }
}

.loader__center {
  margin-top: 3rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.loader--spinner--circle {
  border: 5px solid transparent;
  border-radius: 50%;
}

.loader--spinner--circle__outer {
  width: 100px;
  height: 100px;
  display: table;
  padding: 10px;
  border-top: 5px solid $primary;
  border-bottom: 5px solid $primary;
  animation: outer_circle linear 2s infinite;
}

.loader--spinner--circle__inner {
  width: 10px;
  height: 10px;
  display: table-cell;
  border-right: 5px solid #FAE7B8;
  border-left: 5px solid #FAE7B8;
  animation: inner_circle linear 2s infinite;
}

.loader__with-image {
  .loader--image {
    position: absolute;
    top: 1.75rem;

    svg {
      display: flex;
      max-height: 69px;
      position: relative;
      top: 1rem;
      max-width: 100%;
    }
  }
  .loader--spinner--circle__outer {
    width: 150px;
    height: 150px;
  }
  
  .loader--spinner--circle__inner {
    width: 15px;
    height: 15px;
  }
}

@media #{$lg} {
  .loader {
    &__with__image {
      .loader--image {
        svg {
          display: flex;
          max-height: 100px;
          top: 0;
          max-width: 100%;
        }
      }
      .loader--spinner--circle__outer {
        width: 200px;
        height: 200px;
      }
      
      .loader--spinner--circle__inner {
        width: 20px;
        height: 20px;
      }
    }
  }
}

@keyframes outer_circle {
    0% {
    	transform: rotate(0deg);
    }
    50% {
    	transform: rotate(-180deg);
    }
    100% {
    	transform: rotate(-360deg);
    }
}

@keyframes inner_circle {
    0% {
    	transform: rotate(0deg);
    }
    50% {
    	transform: rotate(-360deg);
    }
    100% {
    	transform: rotate(-540deg);
    }
}
