@mixin lh($className, $size, $name) {
  @if $name == "" {
    .lh--#{$className} {
      &, &.text {
        line-height: #{$size};
      }
    }
  } @else {
    .lh--#{$className}\@#{$name} {
      &, &.text {
        line-height: #{$size};
      }
    }
  }
}

$breakpoints: ("xs": "none", "small": $small, "medium": $medium, "large": $large, "x-large": $x-large);
$sizes: ("0-9": .9, "1": 1, "1-1": 1.1, "1-2": 1.2, "1-3": 1.3, "1-4": 1.4, "1-5": 1.5, "1-6": 1.6, "1-7": 1.7);

// Expand this to work across breakpoints, similar to grid.
@each $name, $breakpoint in $breakpoints {
  @each $lh-name, $size in $sizes {
    @if $breakpoint == "none" {
      @include lh($lh-name, $size, "")
    } @else {
      @media only screen and (min-width: $breakpoint) {
        @include lh($lh-name, $size, $name)
      }
    }
  }
}
