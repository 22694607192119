/*
Font: 		Oakes Grotesk
Style: 		Regular
URL: 		https://www.youworkforthem.com/font/T7517/oakes-grotesk
Foundry: 	Samuel Oakes
Foundry: 	https://www.youworkforthem.com/designer/889/samuel-oakes
Copyright:	\(c\)2017 Oakes is a trademark of Samuel Oakes. All rights reserved.
Version: 	17
Created:	April 15, 2020
License: 	https://www.youworkforthem.com/font-license
License: 	The WebFont(s) listed in this document must follow the YouWorkForThem
			WebFont license rules. All other parties are strictly restricted
			from using the WebFonts(s) listed without a purchased license.
			All details above must always remain unaltered and visible in your CSS.
*/

@font-face {
  font-family: 'OakesGrotesk-Regular';
	//Relative paths can change based on what file @uses this, so absolute paths are preferred.
  src: url('~shared-project-assets/fonts/oakes-grotesk-regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-optical-sizing: auto;
  font-display: swap;
  font-stretch: normal;
}

/*
Font: 		Oakes Grotesk
Style: 		Regular Italic
URL: 		https://www.youworkforthem.com/font/T7517/oakes-grotesk
Foundry: 	Samuel Oakes
Foundry: 	https://www.youworkforthem.com/designer/889/samuel-oakes
Copyright:	\(c\)2017 Oakes is a trademark of Samuel Oakes. All rights reserved.
Version: 	17
Created:	April 15, 2020
License: 	https://www.youworkforthem.com/font-license
License: 	The WebFont(s) listed in this document must follow the YouWorkForThem
			WebFont license rules. All other parties are strictly restricted
			from using the WebFonts(s) listed without a purchased license.
			All details above must always remain unaltered and visible in your CSS.
*/

@font-face {
	font-family: 'OakesGrotesk-Regular';
	src: url('~shared-project-assets/fonts/oakes-grotesk-italic.woff2') format('woff2');
  font-weight: normal;
  font-optical-sizing: auto;
	font-style: italic;
}


/*
Font: 		Oakes Grotesk
Style: 		Medium
URL: 		https://www.youworkforthem.com/font/T7517/oakes-grotesk
Foundry: 	Samuel Oakes
Foundry: 	https://www.youworkforthem.com/designer/889/samuel-oakes
Copyright:	\(c\)2017 Oakes is a trademark of Samuel Oakes. All rights reserved.
Version: 	17
Created:	April 15, 2020
License: 	https://www.youworkforthem.com/font-license
License: 	The WebFont(s) listed in this document must follow the YouWorkForThem
			WebFont license rules. All other parties are strictly restricted
			from using the WebFonts(s) listed without a purchased license.
			All details above must always remain unaltered and visible in your CSS.
*/

@font-face {
  font-family: 'OakesGrotesk-Regular';
  src:
    url('~shared-project-assets/fonts/oakes-grotesk-medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  font-stretch: normal;
}

/*
Font: 		Oakes Grotesk
Style: 		Medium Italic
URL: 		https://www.youworkforthem.com/font/T7517/oakes-grotesk
Foundry: 	Samuel Oakes
Foundry: 	https://www.youworkforthem.com/designer/889/samuel-oakes
Copyright:	\(c\)2017 Oakes is a trademark of Samuel Oakes. All rights reserved.
Version: 	17
Created:	April 15, 2020
License: 	https://www.youworkforthem.com/font-license
License: 	The WebFont(s) listed in this document must follow the YouWorkForThem
			WebFont license rules. All other parties are strictly restricted
			from using the WebFonts(s) listed without a purchased license.
			All details above must always remain unaltered and visible in your CSS.
*/

@font-face {
	font-family: 'OakesGrotesk-Regular';
	src: url('~shared-project-assets/fonts/oakes-grotesk-medium-italic.woff2') format('woff2');
	font-weight: normal;
	font-style: italic;
}


/*
Font: 		Oakes Grotesk
Style: 		Light
URL: 		https://www.youworkforthem.com/font/T7517/oakes-grotesk
Foundry: 	Samuel Oakes
Foundry: 	https://www.youworkforthem.com/designer/889/samuel-oakes
Copyright:	\(c\)2017 Oakes is a trademark of Samuel Oakes. All rights reserved.
Version: 	17
Created:	April 15, 2020
License: 	https://www.youworkforthem.com/font-license
License: 	The WebFont(s) listed in this document must follow the YouWorkForThem
			WebFont license rules. All other parties are strictly restricted
			from using the WebFonts(s) listed without a purchased license.
			All details above must always remain unaltered and visible in your CSS.
*/

@font-face {
  font-family: 'OakesGrotesk-Regular';
  src:
    url('~shared-project-assets/fonts/oakes-grotesk-light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-optical-sizing: auto;
  font-display: swap;
  font-stretch: normal;
}

/*
Font: 		Oakes Grotesk
Style: 		Light Italic
URL: 		https://www.youworkforthem.com/font/T7517/oakes-grotesk
Foundry: 	Samuel Oakes
Foundry: 	https://www.youworkforthem.com/designer/889/samuel-oakes
Copyright:	\(c\)2017 Oakes is a trademark of Samuel Oakes. All rights reserved.
Version: 	17
Created:	April 15, 2020
License: 	https://www.youworkforthem.com/font-license
License: 	The WebFont(s) listed in this document must follow the YouWorkForThem
			WebFont license rules. All other parties are strictly restricted
			from using the WebFonts(s) listed without a purchased license.
			All details above must always remain unaltered and visible in your CSS.
*/

@font-face {
	font-family: 'OakesGrotesk-Regular';
	src: url('~shared-project-assets/fonts/oakes-grotesk-light-italic.woff2') format('woff2');
	font-weight: normal;
	font-style: italic;
}
