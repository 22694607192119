$base-unit: 4;

$base-font-size: $base-unit * 4;
$base-input-font-size: $base-unit * 6;

$minimum-touch-target: 42px;

$small: 768px; // 48em Tablet
$medium: 992px; // 62em
// TODO confirm we don't want to normalize this
$marge: 1024px; // 64em
$large: 1200px; // 75em Desktop
$x-large: 1600px; // 100em Xl Desktop

$page-width: 1060px;
$header-height: 7rem; // 112px when root is 16px
$header-width: 72em; // 1152px @ medium
$xs: 'only screen and (min-width: 0)';
$sm: 'only screen and (min-width: ' + $small + ')';
$md: 'only screen and (min-width: ' + $medium + ')';
$mg: 'only screen and (min-width: ' + $marge + ')';
$lg: 'only screen and (min-width: ' + $large + ')';
$xl: 'only screen and (min-width: ' + $x-large + ')';

$breakpoints: ("xs": "none", "small": $small, "medium": $medium, "marge": $marge, "large": $large, "x-large": $x-large);

$radio-card-border-radius: 8px;

$step-indicator-size: 25px;
$progress-bar-mobile-margin: 2rem;

//Font variables
$font__sans: 'OakesGrotesk-Regular', sans-serif;
$font__sans__bold: 'OakesGrotesk-Medium', sans-serif;
$font__sans__light: 'OakesGrotesk-Light', sans-serif;
$font__monospace: 'Courier New', Courier, monospace;

// If you need to offset a fixed position element from the header, use --header-group-height.
// --header-group-height takes header and any conditional elements that may exist into account.
// Note: Custom properties specifically used here so we can manipulate the values with JavaScript.
:root {
  --header-group-height: #{$header-height};
}
