.print-only {
  display: none;
}

@media print {
  .hide-on-print {
    display: none;
  }

  .print-only {
    display: block;
  }

  //Fixed width to address staging and local printing tests, optimized for a4 page with default margins in chrome browser
  body {
    max-width: 150mm;
  }

  // We should hide the footer whenever we print
  footer {
    display: none;
  }

  // BELOW - Print Quote Review
  .accordion--bubble:not(.accordion--bubble__large),
  .input-textbox--icon,
  .text__link,
  .tooltip__container {
    display: none;
  }

  .accordion--bubble__large {
    background-color: (22, 124, 128, 0.1);
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  kin-review-accordion {
    margin-bottom: 10px;
  }
  // ABOVE - Print Quote Review
}
