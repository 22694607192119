@use 'mixins/shared_buttons';
/**
  start visual styles and states
*/
.btn {
  display: inline-block;
  border: 0;
  border-radius: .5rem;
  width: 100%;
  text-align: center;
  cursor: pointer;
  margin: 0 auto;
  padding: .75rem 1.5rem;
  background-color: $secondary;
  color: $white;
  &:not(.btn__link) {
    font-size: 1.125rem;
  }
  &a {
    text-decoration: none;
  }
  &:hover {
    background-color: $secondary__shade;
  }
  &:focus:not(.btn__link, [disabled]),
  &:active:not(.btn__link, [disabled]) {
    @include shared_buttons.primary-focus;
  }
  &:disabled {
    background-color: $gray;
    cursor: default;
    color: $white;
    border-color: $secondary;
  }

  &__secondary {
    @include shared_buttons.secondary;
    &--gray {
      background-color: $white;
      color: $brand__gray;
      border-color: $gray;
    }
    &:hover {
      @include shared_buttons.secondary-hover;
    }

    &:focus,
    &:active {
      @include shared_buttons.secondary-active;
    }
    &--warning {
      color: $brand__red;
      border-color: $brand__red;
      &:focus,
      &:active,
      &:hover {
        background-color: $brand__red;
        color: $white;
      }
    }
    &:disabled {
      background-color: $white;
      color: $gray;
      border-color: $gray;
    }
  }

  &__link,
  &__link--borderless {
    display: inherit;
    font-family: $font__sans;
    font-weight: 400;
    line-height: 1.25rem;
    color: $secondary;
    padding: 0;
    border: 0;
    background: transparent;
    text-transform: initial;
    cursor: pointer;
    &:focus {
      outline: 1px dotted $black;
    }
  }
  &__link {
    text-decoration: underline;

    &:hover {
      background: transparent;
      box-shadow: none;
      text-decoration: none;
    }
  }
  &__link--borderless {
    text-decoration: none;

    &:hover {
      background: transparent;
      box-shadow: none;
      text-decoration: underline;
    }
  }
  &__link--outline {
    border: 1px solid $gray__light;
    background: transparent;
    color: $brand__gray;
    text-decoration: none;
  }

  /**
    Positioning and size
  */
  @media #{$sm} {
    width: 327px;

    &__cta {
      width: auto;
    }
  }
  /**
  * This should probably be the default
  */
  &__inline {
    display: inline;
  }
  &__auto-width {
    width: auto;
  }
  &__no-margin {
    margin: 0;
  }
  &__stretch {
    width: 100%;
    &__half {
      width: 50%;
    }
  }
  &__cta {
    align-self: flex-start;
    font-size: px-to-rem(18px);
    margin: 0;
    padding: px-to-rem(12px) px-to-rem(32px);
    width: auto;
  }
  &.btn__small {
    width: 224px;
  }
  &.btn__with-icon {
    kin-svg {
      margin-right: 0.5rem;
    }
  }
}
